import { defineStore } from 'pinia'
import { useEntityStore } from '@/areas/editor/store/useEntityStore'
import { computed } from 'vue'
import { useEditorFocusStore, FocusTypes } from '@/store/editor/editorFocus'
import { v4 as uuid } from 'uuid'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import type { Segment } from '@/areas/editor/@type/Project'
import { copyRef } from '@/store/entity-system/_copyRef'
import { usePresets } from '@/areas/editor/@data/layouts'
import { clamp, orderBy } from 'lodash-es'
import { useCropsStore } from '@/areas/editor/store/useCropsStore'
import { contain } from '@/modules/SLMovable/helpers/fit'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useLayoutsStore } from '@/areas/editor/store/useLayoutsStore'

export const useSegmentsStore = defineStore('segments', () => {

  const { state, entities, ids, operations } 
    = useEntityStore<Segment>()
  
  const editorFocusStore = useEditorFocusStore()

  const zooms = computed(() => {
    return orderBy(entities.value.filter(s => s.type === 'zoom'), 'startMs')
  })

  const segments = computed(() => {
    return orderBy(entities.value.filter(s => s.type === 'layout'), 'startMs')
  })
  
  const flatEntities = computed(() => {
    return zipZoomsIntoSegments(copyRef(zooms.value), copyRef(segments.value))
  })
  
  return {
    state: state,
    entities: entities,
    ids: ids,
    

    ...operations,

    createZoom(ms: number, duration: number) {

      const id = uuid()
      const videoStore = useVideoStore()
      const videoDurationMs = videoStore.duration * 1000
      const startMs = clamp(ms - 0.5 * duration, 0, videoDurationMs - duration)
      const endMs = Math.min(startMs + duration, videoDurationMs)
      
      const { applyPreset } = usePresets()
      const layoutId = applyPreset('full')
      
      const currentSegment = segments.value.find(s => s.startMs <= ms && s.endMs >= ms) ?? currentSegmentOrDefault.value
      const cropsStore = useCropsStore()
      const currentCrops = cropsStore.whereLayoutIdIs(currentSegment.layoutId).value
      
      const cropToZoomInOn = currentCrops.find(c => c.input.name === 'Facecam') ?? currentCrops[0]
      if (cropToZoomInOn) {
        const fullscreenCrop = cropsStore.whereLayoutIdIs(layoutId).value[0]
        
        const { width, height } = contain(fullscreenCrop, cropToZoomInOn)
        cropsStore.state[fullscreenCrop.id].width = width
        cropsStore.state[fullscreenCrop.id].height = height
        cropsStore.state[fullscreenCrop.id].x = cropToZoomInOn.x + 0.5 * cropToZoomInOn.width - 0.5 * width
        cropsStore.state[fullscreenCrop.id].y = cropToZoomInOn.y + 0.5 * cropToZoomInOn.height - 0.5 * height
      }

      this.createById(id, {
        startMs: startMs,
        endMs: endMs,
        layoutId: layoutId,
        type: 'zoom'
      })
      
      useHistoryStore().push()

      return id
    },

    whereIsZoom() {
      return zooms
    },
    
    whereIsNotZoom() {
      return segments
    },
    
    flat() {
      return flatEntities
    },
    
    updateTimingsById(id: string, startMs: number, endMs: number) {
      const segment = state[id]
      if (segment) {
        segment.startMs = startMs
        segment.endMs = endMs
      }
    },
    
    splitAt(type: Segment['type'], ms: number) {
    
      // Find segment where current time is at least 500ms from the start and end
      const segments = entities.value
        .filter(s => s.type === type)
        .filter(s => ms - s.startMs > 100 && s.endMs - ms > 100)

      if (segments.length !== 1) {
        return null
      }

      const segment = copyRef(segments[0])
      state[segment.id].endMs = ms

      const layoutsStore = useLayoutsStore()
      const cropsStore = useCropsStore()

      const newLayoutId = uuid()
      const newSegmentId = uuid()
      
      for (const crop of cropsStore.whereLayoutIdIs(segment.layoutId).value) {
        const newCropId = uuid()
        cropsStore.createById(newCropId, {
          ...crop,
          layoutId: newLayoutId,
          id: newCropId
        })
      }
      
      layoutsStore.createById(newLayoutId, {
        ...layoutsStore.state[segment.layoutId],
        id: newLayoutId
      })
      
      this.createById(newSegmentId, {
        startMs: ms,
        endMs: segment.endMs,
        layoutId: newLayoutId,
        type: segment.type,
      })

      editorFocusStore.setFocus('segment', newSegmentId)
    },
    
    pullSegment(id: string, direction: 'left' | 'right', ms: number) {
      
      const segment = state[id]
      if (!segment) return

      if (direction === 'left') {
        segment.endMs = Math.min(segment.endMs, ms)
      } else if (direction === 'right') {
        segment.startMs = Math.max(segment.startMs, ms)
      }
    },
    
    hasFocusById(id: string) {
      const segment = state[id]
      return computed(() => {
        const focusType = segment.type === 'layout' ? FocusTypes.SEGMENT : FocusTypes.ZOOM
        return editorFocusStore.focus 
          && editorFocusStore.focus.type === focusType 
          && editorFocusStore.focus.key === id
      })
    },

    setFocusById(id: string) {
      const segment = state[id]
      const focusType = segment.type === 'layout' ? FocusTypes.SEGMENT : FocusTypes.ZOOM
      editorFocusStore.setFocus(focusType, id)
    },
  }
})

function zipZoomsIntoSegments(zooms: Segment[], segments: Segment[]) {

  const result: Segment[] = []

  for (const segment of segments) {

    const zoomsInSegment = zooms
      .filter(z => z.startMs < segment.endMs && z.endMs > segment.startMs)
      .map(z => copyRef({
        ...z,
        id: z.id + ':' + segment.id,
        startMs: Math.max(z.startMs, segment.startMs),
      }))
    
    if (zoomsInSegment.length === 0) {
      result.push(segment)
      continue
    }

    for (let j = 0; j < zoomsInSegment.length; j++) {

      const zoom = zoomsInSegment[j]

      // Add a segment before the first zoom if necessary
      if (j === 0 && zoom.startMs > segment.startMs) {
        result.push(copyRef({
          ...segment,
          id: segment.id + ':' + result.length,
          endMs: zoom.startMs
        }))
      }

      // Add the zoom
      result.push({
        ...zoom,
        id: zoom.id + ':' + result.length,
        startMs: Math.max(zoom.startMs, segment.startMs),
        endMs: Math.min(zoom.endMs, segment.endMs),
      })
      
      // Insert a segment between the current zoom and the next zoom if necessary
      const endMs = zoomsInSegment[j + 1]?.startMs ?? segment.endMs
      if (zoom.endMs < endMs) {
        result.push(copyRef({
          ...segment,
          id: segment.id + ':' + result.length,
          startMs: zoom.endMs,
          endMs: endMs
        }))
      }
    }
  }

  return result.sort((a, b) => a.startMs - b.startMs)
}
