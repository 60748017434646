<script setup lang="ts">
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import IconSaxDocumentUpload from '@/components/Icons/iconsax/IconSaxDocumentUpload.vue'
import { ref, onMounted, watch } from 'vue'
import { getApiCustomStickers, deleteApiCustomStickersCustomStickerId, postApiCustomStickers } from '@/apis/streamladder-api/custom-stickers/custom-stickers'
import { ScrollArea } from '@/components/ui/scroll-area'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import Tile from '@/components/Tile.vue'
import type { CustomSticker as ApiCustomSticker } from '@/apis/streamladder-api/model'
import { Skeleton } from '@/components/ui/skeleton'
import AppImage from '@/areas/editor/components/AppImage.vue'
import { IconDots } from '@tabler/icons-vue'
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover'
import IconSaxAddSquare from '@/components/Icons/iconsax/IconSaxAddSquare.vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import uploadService from '@/services/uploadService'
import { mbToBytes, formatFileSize } from '@/helpers/fileSize'
import { round } from 'lodash-es'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { Progress } from '@/components/ui/progress'
import IconSaxArrowLeft from '@/components/Icons/iconsax/IconSaxArrowLeft.vue'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { useUserInfoStore } from '@/store/user/userInfo'
import LoginDialog from '@/pages/auth/LoginDialog.vue'
import IconSaxLoginCurve from '@/components/Icons/iconsax/IconSaxLoginCurve.vue'
import { useToast } from '@/Hooks/useToast'
import toastEvents from '@/events/toastEvents'
import EventBus from '@/eventBus'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { useRouter } from 'vue-router'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { canGuard, canGuardWithPopup } from '@/Hooks/useGuard'
import DynamicPlanButtonWithTooltip from '@/components/Account/Upgrade/DynamicPlanButtonWithTooltip.vue'
import logging from '@/logging'
import { setLastUsedCustomStickerFileUrl } from '@/areas/editor/pages/elements/useElementsSettings'

defineProps<{
  hideBackButton?: boolean
}>();

const userInfoStore = useUserInfoStore();
const stickers = ref<ApiCustomSticker[]>([])
const isFetching = ref(false)
async function fetchStickers() {
  isFetching.value = true
  stickers.value = await getApiCustomStickers()
  isFetching.value = false
}

function findStickersByImageUrl(imageUrl: string) {
  const ids = stickersStore.idsWhere(s => s.imageUrl === imageUrl)
  return ids.value
}

const isDeleting = ref<string[]>([])
async function deleteSticker(sticker: ApiCustomSticker) {

  isDeleting.value.push(sticker.id)
  await deleteApiCustomStickersCustomStickerId(sticker.id)

  for (const id of findStickersByImageUrl(sticker.fileUrl)) {
    stickersStore.removeById(id)
  }

  await fetchStickers()
  isDeleting.value = isDeleting.value.filter(id => id !== sticker.id)
}

onMounted(async () => {
  await fetchStickers()
})

const stickersStore = useStickersStore()

async function loadImageByUrl(url: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = () => reject()
    img.src = url
  })
}

const editorFocusStore = useEditorFocusStore()

async function addStickerToProject(sticker: ApiCustomSticker) {

  if (!canGuardWithPopup('stickers')) {
    return;
  }

  try {

    const image = await loadImageByUrl(sticker.fileUrl)
    const drawWidth = 0.8 * 1080
    const drawHeight = image.naturalHeight * (drawWidth / image.naturalWidth)
    
    const id = stickersStore.createCustomSticker({
      imageUrl: sticker.fileUrl,
      naturalHeight: image.naturalHeight,
      naturalWidth: image.naturalWidth,
      area: {
        x: 0.1,
        y: 0.1 + Math.random() * (0.9 - drawHeight / 1920),
        width: drawWidth / 1080,
        height: drawHeight / 1920,
      }
    })

    logging.trackEvent('Editor CustomSticker Added', {
      ImageUrl: sticker.fileUrl,
    })

    setLastUsedCustomStickerFileUrl(sticker.fileUrl)

    setTimeout(() => editorFocusStore.setFocus(FocusTypes.STICKER, id), 0)

    useHistoryStore().push()
  } catch (e) {
    console.error(e)
  }
}

const isProgressDialogOpen = ref(false)
const error = ref<string | null>()

async function uploadImageFile(event: HTMLInputElement) {

  if (!canGuard('stickers')) {
    return;
  }

  const inputElement = event.target as HTMLInputElement
  const result = await tryUploadImageFile(inputElement.files?.item(0))
  
  if (result.error) {
    console.error(result.error)
    error.value = result.error
  } else {
    await fetchStickers()
  }

  inputElement.value = null
}

const { showToast } = useToast()

const router = useRouter()

const uploadPercentage = ref(0)
const maxFileSize = mbToBytes(5)
async function tryUploadImageFile(file: File | null) {

  if (!file) {
    showToast({
      type: toastEvents.TOAST_ERROR,
      title: 'No file selected',
      subtitle: 'Please select a file to upload.',
      timeout: 10000,
    });
    return { error: null }
  }

  if (file.size > maxFileSize) {
    showToast({
      type: toastEvents.TOAST_ERROR,
      title: 'File too large, please try another file',
      subtitle: `Max file size is ${formatFileSize(maxFileSize, 2)}.`,
      timeout: 10000,
    });
    return { error: null }
  }

  try {
    uploadPercentage.value = 0
    isProgressDialogOpen.value = true

    const result = await uploadService.getUploadCustomStickerSignedUrl()
    await uploadService.uploadFileS3(result.signedUrl, file, (progress) => {
      uploadPercentage.value = round(progress, 2)
    })

    await postApiCustomStickers({ fileUrl: result.resultUrl })

    await addStickerToProject(result.resultUrl)
    isProgressDialogOpen.value = false

    return { error: null }
  } catch (e) {
    console.error(e)
    EventBus.$emit(toastEvents.TOAST_ERROR, {
      title: "File upload failed",
      subtitle: "Please try again or contact support.",
      view: () => {
        router.push({
          name: dashboardRouteNames.support,
        })
      },
      viewTitle: 'Support',
    });
    return { error: null }
  }
}

const { currentSection } = useEditorStep()

watch(currentSection,async () => {
  await fetchStickers();
}, { immediate: true });
</script>

<template>
  <section class="flex flex-col gap-4 p-4 pb-0 2xl:p-8 h-full max-h-screen">
    <div class="flex flex-col align-start gap-1 mb-2" v-if="userInfoStore.isAuthenticated">

      <div v-if="!hideBackButton" class="flex items-center gap-2 mb-4 lg:mb-0 cursor-pointer hover:-translate-x-0.5 transition-transform text-brand-state-link hover:underline underline-offset-2" @click="currentSection = null">
        <IconSaxArrowLeft class="w-4 h-4" />
        <span class="text-xl lg:text-sm mt-0.5 select-none">Back to Elements</span>
      </div>

      <header class="hidden md:block">
        <h2 class="text-xl font-semibold leading-snug mt-2">Upload local file</h2>
        <p class="font-light">
          Here you can upload your own stickers and they will be added to your clip.
        </p>
      </header>

      <Button as="label" class="relative gap-2 bg-transparent hover:bg-transparent w-full h-32 font-light text-current active:scale-90 transition-[transform,_border-color,_background-color] border border-dashed border-brand-state-hover-border rounded-lg flex flex-col justify-center items-center cursor-pointer hover:scale-[101%] group">
        <DynamicPlanButtonWithTooltip feature="stickers" class="absolute right-2 top-2" />
        <IconSaxDocumentUpload class="w-5 h-5 text-brand-state-hover-border dark:text-white group-hover:scale-110 group-hover:-translate-y-0.5 transition-transform" />
        <div class="flex flex-col items-center justify-center">
          <span>Upload a sticker</span>
          <span class="text-xs text-gray-500">Max 5 MB files are allowed</span>
          <span class="text-xs text-gray-500">Supported formats: .png, .jpeg, .gif</span>
        </div>
        <div class="absolute w-full h-full" @click="canGuardWithPopup('stickers')">
          <Input
            class="hidden" ref="input" id="custom-sticker-input"
            type="file" name="custom-sticker" accept="image/png, image/jpeg, image/gif"
            @input="uploadImageFile"
            :disabled="!canGuard('stickers')"
          />
        </div>
      </Button>
    </div>
    <template v-else>
      <header class="flex flex-col gap-2 my-5">
          <h2 class="text-xl font-semibold leading-snug">Please login to upload an image</h2>
          <LoginDialog>
            <Button class="w-full ml-auto flex flex-nowrap items-center justify-center gap-2 whitespace-nowrap rounded-lg bg-indigo-500 px-3 py-1 text-white outline-none hover:bg-indigo-600 focus:bg-indigo-600 transition-all">
              <IconSaxLoginCurve class="h-4 w-4 shrink-0 [&>path]:stroke-1" />
              Login
            </Button>
          </LoginDialog>
        </header>
      </template>

    <template v-if="stickers.length > 0 || isFetching">
      <div class="flex flex-col align-start gap-2 min-h-0 h-full">
        <h2 class="text-lg font-medium leading-snug">My images</h2>
        <ScrollArea class="min-h-0 overflow-y-auto -mx-4 px-4 2xl:-mx-8 2xl:px-8">
          <div class="grid gap-2 grid-cols-2 lg:grid-cols-[repeat(auto-fill,_minmax(120px,_1fr))]">
            <template v-if="isFetching">
              <Skeleton v-for="i in 3" :key="i" class="aspect-square w-full border border-surface-panel-border" />
            </template>
            <template v-else>
              <template v-for="sticker in stickers" :key="sticker.id">
                <Tile 
                  @click="() => addStickerToProject(sticker)"
                  class="active:scale-90 transition-[transform,_border-color,_background-color] group"
                  v-if="!isDeleting.includes(sticker.id)"
                >
                  <DynamicPlanButtonWithTooltip feature="stickers" class="absolute right-2 top-2" />

                  <div class="aspect-square max-w-[80%]">
                    <AppImage class="h-full w-full cursor-pointer object-contain" :src="sticker.fileUrl" alt="Custom sticker preview" />
                  </div>
      
                  <Popover>
                    <PopoverTrigger @click.stop>
                      <div class="rounded m-1 px-1 py-0 hover:bg-white text-black hover:text-gray-600 absolute right-0 hover:shadow-xl bottom-0 opacity-0 bg-white/50 group-hover:opacity-100 transition-all">
                        <IconDots />
                      </div>
                    </PopoverTrigger>
                    <PopoverContent @click.stop class="flex flex-col p-0 w-auto overflow-hidden border-surface-panel-border layer-2">
                      <Button
                        variant="ghost"
                        class="rounded-none flex items-center hover:bg-zinc-100 font-light cursor-pointer"
                        @click="addStickerToProject(sticker)"
                      >
                        <IconSaxAddSquare class="w-4 h-4" />
                        <span class="mt-0.5">Add to video</span>
                      </Button>
                      <Button
                        variant="ghostDestructive"
                        class="rounded-none flex items-center font-light cursor-pointer"
                        @click.stop="deleteSticker(sticker)"
                      >
                        <IconSaxTrash class="w-4 h-4" />
                        <span class="mt-0.5">Delete from library</span>
                      </Button>
                    </PopoverContent>
                  </Popover>
                </Tile>
                <Skeleton v-else class="aspect-square w-full border border-surface-panel-border" />
              </template>
            </template>
          </div>
        </ScrollArea>
      </div>
    </template>

    <Dialog v-model:open="isProgressDialogOpen">
      <DialogContent>
        <div class="flex flex-col items-center justify-center gap-4">
          <LottieAnimation url="/lottie/rocketLaunch.json" class="w-20" />
          <h2 class="text-center text-2xl font-bold">Hold tight! ✨</h2>
          <p class="text-center text-lg font-light opacity-75">We're saving your custom sticker!</p>
          <Progress :model-value="uploadPercentage" />
        </div>
      </DialogContent>
    </Dialog>
  </section>
</template>

<style scoped lang="scss">

</style>
