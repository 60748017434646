import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { startupVideo, startupWithDefaultLayout, cleanupCaptionStorage } from '@/areas/editor/startup/generalStartupMethods'
import { getApiYouTubeClipsClipId } from '@/apis/streamladder-api/you-tube-clips/you-tube-clips'
import { useWebsocketChannel } from "@/modules/WebsocketService/WebSocketService";
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import type { StartupConfig } from '@/areas/editor/startup/StartupConfig'
import { handleStartupError } from '@/areas/editor/startup/handleStartupError'
import { throwIfBrowserUnsupported } from '@/areas/editor/startup/UnsupportedBrowserError'
import type { ClipDto } from '../@type/ClipDto';

export async function startupFromYoutubeClipByClipId(clipId: string, config: StartupConfig) {

  const editorClipInfoStore = useEditorClipInfoStore()
  try {
    throwIfBrowserUnsupported()
    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Fetching clip info...'
    }
    const response = await getApiYouTubeClipsClipId(clipId)
    config.signal.value?.throwIfAborted()
    return await startupFromYoutubeClip(response as unknown as ClipDto, config)
  } catch (error) {
    return handleStartupError(error)
  }
}

export async function startupFromYoutubeClip(clip: ClipDto, config: StartupConfig) {

  const editorClipInfoStore = useEditorClipInfoStore()
  editorClipInfoStore.id = clip.id
  editorClipInfoStore.title = clip.title ?? 'YouTube clip'
  editorClipInfoStore.viewCount = clip.viewCount
  editorClipInfoStore.mp4Url = clip.mp4Url
  editorClipInfoStore.thumbnailUrl = clip.thumbnailUrl
  editorClipInfoStore.source = 'youtube-clip'
  editorClipInfoStore.languageCode = clip.languageCode?.toLowerCase() ?? 'en_us'
  editorClipInfoStore.isLocalFile = false

  cleanupCaptionStorage()
  
  // Push all we know about the clip to the history store, so we can resume from 
  // here on reload.
  const historyStore = useHistoryStore()
  await historyStore.push()
  
  config.signal.value?.throwIfAborted()

  editorClipInfoStore.isLoadingClip = true

  const response = await tryDownloadClip(clip, config)
  config.signal.value?.throwIfAborted()

  if ('error' in response) {
    if (response.error) {
      editorClipInfoStore.loadingState = {
        state: 'error',
        ...explanationFor(response.error)
      };
    }
    return {
      error: response.error
    };
  } else {

    editorClipInfoStore.mp4Url = response.mp4Url;
    editorClipInfoStore.title = response.title || 'YouTube clip'

    try {
      await startupVideo(config)
      config.signal.value?.throwIfAborted()

      await startupWithDefaultLayout(config)
      config.signal.value?.throwIfAborted()

      editorClipInfoStore.isLoadingClip = false
      editorClipInfoStore.loadingState = null

      // If the startup process was successful, replace the history state
      await historyStore.replace()
      return { error: null }
    } catch (error) {
      return handleStartupError(error)
    }
  }
}

async function tryDownloadClip(clip: ClipDto, config: StartupConfig) {

  const editorClipInfoStore = useEditorClipInfoStore()

  // If we get an empty response from a YouTube clip, we have to listen to the pusher client to retrieve the info needed.
  if (clip.mp4Url === '') {

    editorClipInfoStore.loadingState = {
      state: 'loading',
      description: 'Importing YouTube Clip. This may take a few minutes...'
    }

    console.log('Empty response, listening to pusher client')
    try {
      return await listenForYoutubeClipDownload(clip.taskId, config)
    } catch (e) {
      return { error: e?.toString() ?? null }
    }
  } else {
    return clip
  }
}

export async function listenForYoutubeClipDownload(taskId: string, config?: StartupConfig) {

  return new Promise<ClipDto>((resolve, reject) => {

    const channelName = `cache-task-status-${taskId}`;
    const timeout = setTimeout(() => {
      channel.value?.disconnect()
      reject(new Error('YouTube clip download timed out.'))
    }, 20 * 60 * 1000)

    const channel = useWebsocketChannel(channelName, (eventName, data) => {
      if (config?.signal.value?.aborted) {
        channel.value?.disconnect()
        reject(new DOMException('Aborted', 'AbortError'))
        clearTimeout(timeout)
        return
      }

      if (eventName === 'progress') {
        if (data.status === 'finished') {
          resolve(data as ClipDto)
          clearTimeout(timeout)
          return
        } else if (data.status === 'error') {
          reject(data.message)
          clearTimeout(timeout)
        }
      }
    })
  })
}

function explanationFor(error: string) {
  switch (error) {
    case 'blocked_video':
      return {
        description: 'This video is geo-blocked in the USA and could not be imported. Please ensure the original video is available in the USA.'
      }
    default:
      return {
        title: 'Could not import clip 🚫',
        description: 'Looks like your clip is either still processing on YouTube (stream clips take up to 24 hours) or it wasn\'t found. Try another clip or check back later!'
      }
  }
}
