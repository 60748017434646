<script setup lang="ts">
import { useToast } from '@/Hooks/useToast'
import { canGuardWithPopup } from '@/Hooks/useGuard'
import { posthog } from 'posthog-js'
import { useFileUploads } from '@/components/Dialog/MultiUploadDialog/file-uploads/useFileUploads'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import EventBus from '@/eventBus'
import mainEvents from '@/events/mainEvents'
import * as Sentry from '@sentry/vue'
import { Button, type ButtonVariants } from '@/components/ui/button'
import IconSaxGalleryExport from '@/components/Icons/iconsax/IconSaxGalleryExport.vue'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useRouter } from 'vue-router'
import { onMounted, onUnmounted, ref } from 'vue'
import logging from '@/logging'
import { useStreamLadderRender } from '@/areas/editor/hooks/useStreamLadderRender'
import { runWebcodecTest } from '@/webcodec-renderer/worker/webcodec-test'
import { useUserInfoStore } from '@/store/user/userInfo'
import { tiers } from '@/enums/tiers'
import { useIsMobile } from '@/Hooks/useIsMobile'
import RadialProgress from '@/components/Dialog/RadialProgress.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import type { Upload } from '@/components/Dialog/MultiUploadDialog/file-uploads/Upload'
import Spinner from '@/components/Icons/Spinner.vue'
import toastEvents from '@/events/toastEvents'
import type { RenderDto } from '@/apis/streamladder-api/model'
import { orderBy } from 'lodash-es'
import { upgradeDialog } from '@/helpers/upgradeDialog'

const { getVideoRenderData, requestVideoRender, requestWebcodecRender, getAllRenders } = useStreamLadderRender()

const { dismissPremiumToasts } = useToast()
const isMobile = useIsMobile()

const router = useRouter()
const stickersStore = useStickersStore()
const captionsStore = useEditorCaptionsStore()
const clipInfoStore = useEditorClipInfoStore()
const userInfoStore = useUserInfoStore()

const clipUpload = ref<Upload | null>(null)
const progressDialogIsOpen = ref(false)

const { showToast } = useToast();

async function render() {

  dismissPremiumToasts()

  const hasStickers = stickersStore.ids.length > 0
  if (hasStickers && !canGuardWithPopup('stickers')) {
    return
  }

  const hasCaptions = captionsStore.hasCaptions
  if (hasCaptions && !canGuardWithPopup('captions')) {
    return
  }

  const giphyStickers = stickersStore.entities.filter((sticker) => sticker.type === 'giphy')
  const hasGiphyStickers = giphyStickers.length > 0
  if (hasGiphyStickers) {

    if (!canGuardWithPopup('giphy-stickers')) {
      return;
    }

    posthog.capture('Giphy Stickers: user clicked export with Giphy stickers', {
      selectedStickerUrls: giphyStickers.map(s => s.imageUrl),
    });
  }

  const fileUploads = useFileUploads()
  clipUpload.value = fileUploads.selectById(clipInfoStore.id).value

  if (clipUpload.value?.suspense) {
    progressDialogIsOpen.value = true
    console.log('Wait for file upload to finish..')
    await clipUpload.value.suspense()
  }

  if (clipUpload.value) {
    if (clipUpload.value.urls?.videoSignedUploadRequest?.resultUrl) {
      clipInfoStore.mp4Url = `${clipUpload.value.urls.videoSignedUploadRequest.resultUrl}?cache=${Date.now()}`;
    } else {
      console.error('Error uploading video')
    }
  }

  if (clipInfoStore.mp4Url.startsWith('blob:')) {
    console.log('At upload', JSON.stringify(clipUpload))
    Sentry.captureException(new Error('Invalid mp4 URL: ' + clipInfoStore.mp4Url))
  }

  let renderOnServer = isMobile.value || userInfoStore.tier === tiers.GOLD;

  if (!isMobile.value && userInfoStore.tier !== tiers.GOLD) {
    const webcodecTestResults = await runWebcodecTest();
    if (webcodecTestResults.hardwareAccelerationWorking || webcodecTestResults.softwareEncoderWorking) {
      renderOnServer = false;
    } else {
      logging.trackEvent('WebCodec Renderer Client not supported', {
        webcodecTestResults
      })
    }
  }

  const renderData = getVideoRenderData()
  const renderId = await requestWebcodecRender(renderOnServer, renderData);

  logging.trackEvent('Clip Created', {
    source: clipInfoStore.source,
    isLocalFile: clipInfoStore.isLocalFile,
  });

  if (renderId) {
    if (renderOnServer) {
      await router.push({
        name: 'GenerateServerSideQueue',
        query: {
          task: renderId,
        },
      })
    } else {
      await router.push({
        name: 'GenerateVideo',
        query: {
          renderId: renderId,
        },
      })
    }
  } else {
    const result = await requestVideoRender()

    if (!result) {
      EventBus.$emit(mainEvents.ERROR, `Something went wrong with rendering.<br/><br/>Please open a Ticket on Discord.`)
    } else if (result.type === 'error' && result.message === 'Video is already rendering') {
      showToast({
        type: toastEvents.TOAST_ERROR,
        title: "There's already a video rendering with this clip",
        subtitle: 'Please wait until the other render is finished 🙊',
        timeout: 10000,
      })
    } else if (result.type === 'error' && result.message !== 'User is not authenticated') {
      EventBus.$emit(mainEvents.ERROR, `Something went wrong with rendering <strong>${result.message}</strong>.${'taskId' in result && result.taskId ? 'Please open a Ticket on Discord with TaskId:<br/> ' + result.taskId : ''}`)
    } else if (result.type === 'server' && 'task' in result && result.task) {
      await router.push({
        name: 'GenerateServerSideQueue',
        query: {
          task: result.task.id,
        },
      })
    }
  }
}

const isDisabled = ref(false);
const isRendering = ref(false);

async function startRender() {
  isRendering.value = true
  await render()
  isRendering.value = false
}

function countsAsSameRender(a: RenderDto, b: RenderDto) {
  return a.source === b.source 
    && a.title === b.title 
    && Math.abs(new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime()) < 2 * 60 * 1000;
}

async function fetchUniqueRenders() {

  const renders = orderBy(await getAllRenders(), r => new Date(r.updatedAt!), 'desc')
  const uniqueRenders = [] as RenderDto[]
  
  for (const render of renders) {
    if (!uniqueRenders.some(r => countsAsSameRender(r, render))) {
      uniqueRenders.push(render)
    }
  }
  
  return uniqueRenders
}

function taskIsActive(render: RenderDto) {
  const timeSinceCreationMs = new Date().getTime() - new Date(render.createdAt!).getTime()
  return render.status === 'rendering' && timeSinceCreationMs <= 10 * 60 * 1000;
}

async function canUserRenderAsync() {

  const renders = (await fetchUniqueRenders()).filter(taskIsActive)
  
  if (userInfoStore.tier === tiers.GOLD) {
    return renders.length < 5;
  } else {
    return renders.length < 2;
  }
}

onMounted(async () => {

  if (!userInfoStore.isLoggedIn) {
    return;
  }

  isDisabled.value = true

  const canRender = await canUserRenderAsync()
  if (canRender) {
    isDisabled.value = false;
  } else {
    startPollingForActiveRenders()
  }
});

const interval = ref();
const startPollingForActiveRenders = () => {
  interval.value = setInterval(async () => {
    const canRender = await canUserRenderAsync()
    isDisabled.value = !canRender
  }, 5000);
};

onUnmounted(() => {
  clearInterval(interval.value);
});

withDefaults(defineProps<{
  variant?: ButtonVariants['variant']
  size?: ButtonVariants['size']
}>(), {
  variant: 'primary',
  size: 'lg',
})
</script>

<template>
  <Button :variant="variant" :size="size" @click="startRender" :disabled="isDisabled || isRendering">
    <template v-if="!isRendering && !isDisabled">
      <slot>
        <IconSaxGalleryExport class="w-5 h-5" />
        Export
      </slot>
    </template>
    <template v-else-if="isDisabled">
      Export
      <Spinner class="h-4 w-4 animate-spin mr-2.5" />
    </template>
    <template v-else>
      <div class="w-5 h-5 border-2 border-current border-t-transparent rounded-full animate-spin" />
      Starting export...
    </template>
  </Button>

  <Dialog v-model:open="progressDialogIsOpen" v-if="clipUpload">
    <DialogContent class="flex flex-col items-center gap-4 py-4">
      <template v-if="clipUpload.status === 'in-progress'">
        <LottieAnimation url="/lottie/rocketLaunch.json" class="w-20" />
        <h2 class="text-center text-2xl font-bold dark:text-white">Hold tight! ✨</h2>
        <p class="text-center text-lg font-normal text-gray-600 dark:text-white">
          We're zipping your video over to our ultra-fast rendering buddy.
        </p>
        <radial-progress :progress="clipUpload.progress" />
      </template>
      <template v-else-if="clipUpload.status === 'finished'">
        <LottieAnimation url="/lottie/rocketLaunch.json" class="w-20" />
        <h2 class="text-center text-2xl font-bold dark:text-white">Success! 🚀</h2>
        <p class="text-center text-lg font-normal text-gray-600 dark:text-white">
          Your video is ready to be rendered. 🚀
        </p>
      </template>
      <template v-else-if="clipUpload.status === 'error'">
        <LottieAnimation url="/lottie/error.json" class="w-20" />
        <h2 class="text-center text-2xl font-bold dark:text-white">Oops!</h2>
        <p class="text-center text-lg font-normal text-gray-600 dark:text-white">An error occurred while uploading your video</p>
      </template>
      <template v-else-if="clipUpload.status === 'invalid'">
        <LottieAnimation url="/lottie/error.json" class="w-20" />
        <h2 class="text-center text-2xl font-bold dark:text-white">Oops!</h2>
        <span v-if="clipUpload.validationResult?.error" class="flex flex-col">
          <span class="text-center text-lg font-normal text-gray-600 dark:text-white">{{ clipUpload.validationResult.error.title }}</span>
          <span class="text-center text-sm font-light text-gray-600 dark:text-white opacity-75">{{ clipUpload.validationResult.error.message }}</span>
        </span>

        <template v-else-if="clipUpload.validationResult?.guard">
          <span class="text-center text-lg font-normal text-gray-600 dark:text-white">
            You need to upgrade to be able export this video.
          </span>
          
          <Button @click="upgradeDialog.open(clipUpload.validationResult?.guard)">
            Upgrade
          </Button>
        </template>
      </template>
      <template v-else-if="clipUpload.status === 'pending'">
        <LottieAnimation url="/lottie/rocketLaunch.json" class="w-20" />
        <h2 class="text-center text-2xl font-bold dark:text-white">Hold tight! ✨</h2>
        <p class="text-center text-lg font-normal text-gray-600 dark:text-white">
          We're zipping your video over to our ultra-fast rendering buddy.
        </p>
      </template>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss">

</style>
