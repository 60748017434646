import { createClient, type Session } from '@supabase/supabase-js'
import { getPopupFeatures } from '@/helpers/PopupWindowHelper'
import { accountsAxios } from '@/services/axios'
import logging from '@/logging'
import { useUserInfoStore } from '@/store/user/userInfo'
import EventBus from '@/eventBus'
import authEvents from '@/events/authEvents'
import type { RouteLocation } from 'vue-router'
import { queryClient } from '@/services/QueryClient'
import { unref } from 'vue'

const url = import.meta.env.VITE_SUPABASE_URL
const key = import.meta.env.VITE_SUPABASE_KEY

const supabase = createClient(url, key)

export async function getSession() {
  return await supabase.auth.getSession()
}

export async function refreshSession() {
  return await supabase.auth.refreshSession()
}

export async function requestUserSignInAsync(title?: string) {
  const userInfoStore = useUserInfoStore()
  return await new Promise<boolean>((resolve) => {
    if (userInfoStore.isAuthenticated) {
      return resolve(true);
    } else {
      EventBus.$emit(authEvents.OPEN_LOGIN_DIALOG, {
        title: title,
        callback() {
          resolve(userInfoStore.isAuthenticated)
        } 
      })
    }
  })
}

export async function signInWith(provider: 'google' | 'twitch') {
  return await signInWithOAuth(provider)
}

export async function signOut(route: RouteLocation) {

  await supabase.auth.signOut()

  const userInfoStore = useUserInfoStore()
  await userInfoStore.updateUserInfo()
  queryClient.invalidateQueries()
  if (route.meta.requiresAuth) {
    window.location.href = '/'
  } else {
    window.location.reload()
  }
}

async function signInWithOAuth(provider: 'google' | 'twitch') {

  const { data, error } = await supabase.auth.signInWithOAuth({ 
    provider: provider,
    options: {
      redirectTo: window.location.origin + '/signin-callback',
      skipBrowserRedirect: true
    },
  })

  if (error) {
    throw error
  }
  
  if (!data?.url) {
    throw new Error('Invalid login URL')
  }

  const session = await openAuthenticationWindow(data.url, provider)
  if (session) {
    await updateUserDataInStore()
    await updateUserDataInBackend(session)
    return session
  } else {
    throw error ?? new Error('Failed to get session')
  }
}

async function updateUserDataInStore() {
  const userInfo = useUserInfoStore()
  await userInfo.updateUserInfo()
  logging.trackEvent('Logged In', {
    $set_once: {
      created_at: userInfo.createdAt
    }
  })
}

async function updateUserDataInBackend(session: Session) {

  const referrer = localStorage.getItem('refCode');
  const url = referrer ? `/loggedin?referrer=${referrer}` : '/loggedin'
  const headers = { Authorization: `Bearer ${session.access_token}` }
  const response = await accountsAxios.post(url, null, { headers })
  
  if (response.status === 200) {
    if (response.data.shouldRefreshToken as boolean) {
      await refreshSession()
    }
  } else {
    throw new Error('Failed to update user data in backend')
  }
}

async function openAuthenticationWindow(url: string, provider: 'google' | 'twitch') {
  return new Promise<Session | null>((resolve, reject) => {

    const authenticationWindow = window.open(url, provider.toUpperCase(), getPopupFeatures())
    if (!authenticationWindow) {
      return reject('Failed to open popup window')
    }
    
    supabase.auth.onAuthStateChange((event, session) => {
      switch (event) {
        case 'SIGNED_IN':
          resolve(session)
          break
      }
    })
  })
}
