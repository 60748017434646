<script setup lang="ts">
import { Button } from '@/components/ui/button'
import { ScrollArea } from '@/components/ui/scroll-area'
import GiphyElement from '@/components/Giphy/GiphyElement.vue'
import type { IGif } from '@giphy/js-types'
import { computed, onMounted, ref, watch } from 'vue'
import { useInfiniteQuery } from '@tanstack/vue-query'
import { GiphyService } from '@/components/Giphy/GiphyService'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import { useEditorFocusStore, FocusTypes } from '@/store/editor/editorFocus'
import compatabilityChecker from '@/services/compatabilityChecker.js'
import Image from '@/areas/editor/components/AppImage.vue'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useVideoStore } from '../../../store/useVideoStore'
import { clamp } from 'lodash-es'
import { onUserInfoReady, useUserInfoStore } from '@/store/user/userInfo'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { editorRouteNames } from '@/areas/editor/routeNames'
import { useScreen } from '@/Hooks/useScreen'
import { getLastUsedGiphyStickerId, setLastUsedGiphyStickerId } from '@/areas/editor/pages/elements/useElementsSettings'
import logging from '@/logging'

type Giph = {
  id: string;
  url: string;
  gif: IGif;
};

const selectedFilter = ref<(typeof filters)[number]['key']>('stickers')
const filters = [
  {
    key: 'stickers',
    name: 'Stickers',
  },
  {
    key: 'gifs',
    name: 'Gifs',
  },
]

const defaultStickerIds = [
  '7XRpiNVdVP83Z1Vtte',
  'IxxwlgHmmkEW9bdsLL',
  'di6rzuTRYgDOZ1f9ud',
  'XeXtOVyYF961jhiPbt',
  'FaGFQRGDArn3QpLbPc',
  'ekIiUW41nR5PUA6psw',
  'lVZnnXraJX440',
  'JY5dig6xQ9S7u',
  '3YAxVrJrjWIeeADRM7',
];

const { data } = useInfiniteQuery({
  queryKey: ['giphy'],
  queryFn: async () => {
    if (selectedFilter.value === 'stickers') {
      return GiphyService.gifs(defaultStickerIds);
    }
  },
  initialPageParam: 0,
  getNextPageParam: (lastPage) => {
    return lastPage.pagination.offset + lastPage.pagination.count
  },
  placeholderData: (data) => data,
  staleTime: 1000 * 60 * 5 // 5 minutes
})

const toListItem = (gif: IGif): Giph => ({
  id: gif.id.toString(),
  url: gif.images.downsized_medium.url,
  gif: gif,
})

const is2Xl = useScreen('2xl')
const maxItems = computed(() => {
  return is2Xl.value ? 8 : 4;
});

const gifs = computed(() => {
  if (lastUsedGiphy.value) {
    return data.value?.pages
      .flatMap(page => page.data.map(toListItem))
      .slice(0, maxItems.value - 1);
  } else {
    return data.value?.pages
      .flatMap(page => page.data.map(toListItem))
      .slice(0, maxItems.value);
  }
});

const stickersStore = useStickersStore()
const focusStore = useEditorFocusStore()

const videoStore = useVideoStore();

async function gifSelected({ gif }: Giph) {

  logging.trackEvent('Editor GiphySticker Added', {
    GiphyId: gif.id,
  })

  const aspectRatio = gif.images.original.width / gif.images.original.height;
  const screenWidth = 1080;
  const screenHeight = 1920;
  const absoluteWidth = screenWidth * 0.5;
  const absoluteHeight = absoluteWidth / aspectRatio;

  const gifVideo = document.createElement('video');
  gifVideo.src = gif.images.fixed_width_small.mp4;
  await new Promise((resolve) => {
    gifVideo.ondurationchange = () => resolve(null);
    gifVideo.onerror = () => resolve(null);
  })

  const videoDurationMs = videoStore.videoElement!.duration * 1000;
  const videoCurrentTimeMs = videoStore.videoElement!.currentTime * 1000;
  const minDurationMs = Math.max(gifVideo.duration * 1000, Math.min(videoDurationMs, 5000))

  const startMs = clamp(videoCurrentTimeMs, 0, videoDurationMs - minDurationMs)
  const endMs = Math.min(startMs + minDurationMs, videoDurationMs)

  const stickerId = stickersStore.createGiphySticker({
    imageUrl: gif.images.original.webp,
    naturalWidth: gif.images.original.width,
    naturalHeight: gif.images.original.height,
    key: gif.id.toString(),
    area: {
      x: (0.5 * screenWidth - 0.5 * absoluteWidth) / screenWidth,
      y: 0.1 + Math.random() * (0.9 - absoluteHeight / screenHeight),
      width: absoluteWidth / screenWidth,
      height: absoluteHeight / screenHeight,
    },
    startMs: startMs,
    endMs: endMs,
    editing: false,
  })

  setLastUsedGiphyStickerId(gif.id.toString());

  if (compatabilityChecker.isMobile()) {
    window.scrollTo(0, 0);
  }

  setTimeout(() => focusStore.setFocus(FocusTypes.STICKER, stickerId), 0)

  useHistoryStore().push()
}

const userInfoStore = useUserInfoStore()

const stickerText = ref('')
onUserInfoReady(() => {
  if (!stickerText.value) {
    stickerText.value = userInfoStore.userName || 'StreamLadder'
  }
})

const { currentSection } = useEditorStep()
const viewAll = () => {
  currentSection.value = editorRouteNames.gifs
};

const lastUsedGiphy = ref<null | Giph>(null);

watch(currentSection, async () => {
  const lastUsedGiphyStickerId = getLastUsedGiphyStickerId();
  if (lastUsedGiphyStickerId) {
    const response = await GiphyService.gif(lastUsedGiphyStickerId);
    lastUsedGiphy.value = toListItem(response.data);
  }
}, { immediate: true });
</script>

<template>
  <div class="flex flex-row gap-2 justify-between max-h-[300px] items-center">
    <h2 class="text-base font-semibold leading-snug">Animated stickers</h2>
    <a href="https://giphy.com/" target="_blank" class="w-16 -mt-0.5">
      <Image src="/images/giphy/powered-by.png" alt="Powered by Giphy" class="max-w-full rounded hover:scale-105 transition-all" />
    </a>
    <Button variant="link" class="font-semibold lg:text-sm p-0 h-full" @click="viewAll">
      View all
    </Button>
  </div>

  <ScrollArea class="overflow-y-auto -mx-4 px-4 2xl:-mx-8 2xl:px-8 min-h-[200px] " v-if="gifs && gifs.length > 0">
    <div class="h-full p-1 grid gap-[8px] grid-rows-[repeat(auto-fill,_4px)] grid-cols-[repeat(auto-fill,_minmax(100px,_1fr))]">
      <template v-if="lastUsedGiphy">
        <GiphyElement :key="lastUsedGiphy.id" :item="lastUsedGiphy" @click="gifSelected(lastUsedGiphy)" />
      </template>
      <GiphyElement v-for="item in gifs" :key="item.id" :item="item" @click="gifSelected(item)" />
    </div>
  </ScrollArea>
</template>

<style scoped lang="scss">

</style>