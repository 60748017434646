<script setup lang="ts">
import { type LayoutPreset, usePresets } from '@/areas/editor/@data/layouts'
import { computed, ref } from 'vue'
import { useLayoutsStore } from '@/areas/editor/store/useLayoutsStore'
import { useCropsStore } from '@/areas/editor/store/useCropsStore'
import { omit } from 'lodash-es'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { v4 as uuid } from 'uuid'
import LayoutPreview from '@/areas/editor/components/LayoutPreview.vue'
import type { Crop, Sticker } from '@/areas/editor/@type/Project'
import { useLocalStorage } from '@vueuse/core'
import { resizeAspectLockedFromCenter } from '@/modules/SLMovable/helpers/resize/resizeAspectLockedFromCenter'
import { useCurrentSegment } from '@/areas/editor/store/useCurrentSegment'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { IconDots } from '@tabler/icons-vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import { useConfirmDialog } from '@/components/Dialog/Confirm/useConfirmDialog'
import templateService from '@/services/templateService'

defineProps<{ preset: LayoutPreset }>()

const videoStore = useVideoStore()

const segmentsStore = useSegmentsStore()
const segment = useCurrentSegment()
const layoutId = computed(() => segment.value.layoutId)

const layoutsStore = useLayoutsStore()
const layout = computed(() => layoutsStore.state[layoutId.value])

const cropsStore = useCropsStore()
const stickersStore = useStickersStore()
const selectedPreset = computed(() => layout.value?.presetId ?? null)

function findSimilarCrop(crops: Omit<Crop, 'layoutId'>[], crop: Omit<Crop, 'layoutId'>) {
  return crops.find((c) => c.input.name === crop.input.name)
}

const defaultPreset = useLocalStorage('defaultPreset', 'split')
function select(preset: LayoutPreset) {

  const { layouts } = usePresets()

  if (preset.id !== selectedPreset.value) {

    defaultPreset.value = preset.id
    const oldPreset = layouts.value.find((layout) => layout.id === selectedPreset.value)

    const oldLayoutId = layout.value.id
    const layoutId = uuid()
    layoutsStore.createById(layoutId, {
      name: preset.name,
      presetId: preset.id,
    })

    const currentCrops = cropsStore.whereLayoutIdIs(oldLayoutId)
    const matchedIds = [] as string[]
    for (const crop of preset.crops ?? []) {

      const cropId = uuid()

      // Attempt to replace equal crops
      const oldCrop = findSimilarCrop(currentCrops.value.filter(c => !matchedIds.includes(c.id)), crop)
      if (preset.origin !== 'saved-template' && oldCrop) {
        matchedIds.push(oldCrop.id)
        const newCropSize = resizeAspectLockedFromCenter(
          oldCrop,
          { x: oldCrop.x + oldCrop.height, y: oldCrop.y + oldCrop.height },
          ['s', 'e'],
          { top: 0, left: 0, right: 1, bottom: 1 },
          { width: crop.width, height: crop.height },
          null)

        cropsStore.createById(cropId, {
          ...omit(crop, 'id'),
          layoutId: layoutId,
          ...newCropSize
        })
      } else {
        cropsStore.createById(cropId, {
          ...omit(crop, 'id'),
          layoutId: layoutId,
        })
      }
    }

    const fitStickerWithinTimeline = (sticker: Sticker) => {

      const stickerDuration = sticker.endMs - sticker.startMs;

      const newEndMs = sticker.endMs > videoStore.durationMs
        ? videoStore.durationMs
        : sticker.endMs;

      const newStartMs = sticker.startMs >= newEndMs
        ? Math.max(0, newEndMs - stickerDuration)
        : sticker.startMs;

      return {
        ...sticker,
        endMs: newEndMs,
        startMs: newStartMs,
      } as Sticker;
    };

    const segmentsWithThisLayout = segmentsStore.ids
      .filter(id => segmentsStore.state[id].layoutId === oldLayoutId)

    if (segmentsWithThisLayout.length === 1) {
      layoutsStore.removeById(oldLayoutId)
      for (const oldCrop of currentCrops.value) {
        cropsStore.removeById(oldCrop.id)
      }

      for (const oldSticker of oldPreset?.stickers ?? []) {
        stickersStore.removeById(oldSticker.id)
      }
    }

    for (const sticker of preset.stickers ?? []) {
      if (!stickersStore.selectById(sticker.id)) {
        stickersStore.createById(sticker.id, fitStickerWithinTimeline(sticker))
      }
    }

    segmentsStore.state[segment.value.id].layoutId = layoutId

    useHistoryStore().push()
  }
}

const dialog = useConfirmDialog()
const isRemoving = ref<string>()

async function revealConfirmDialog(template: LayoutPreset) {
  isRemoving.value = template.id
  await dialog.reveal({
    title: 'Are you sure you want to remove this template?',
    message: 'This action cannot be undone.',
  })
}

dialog.onConfirm(async () => {
  if (isRemoving.value) {
    await templateService.deleteTemplate(isRemoving.value)
  }
  isRemoving.value = undefined
})

dialog.onCancel(() => {
  isRemoving.value = undefined
})
</script>

<template>
  <article
    ref="container"
    class="group rounded-xl overflow-hidden p-1 active:scale-90 transition-[background-color,_transform] border border-surface-panel-border cursor-pointer shadow flex flex-col gap-1"
    :class="preset.id === selectedPreset
        ? 'bg-brand-state-hover-border hover:bg-brand-state-hover-border dark:hover:bg-brand-state-hover-border'
        : 'bg-white dark:bg-black hover:!bg-brand-state-hover-bg hover:!border-brand-state-hover-border'"
    @click="select(preset)"
  >
    <h3
      class="line-clamp-2 text-center text-sm font-semibold my-1"
      :class="preset.id === selectedPreset ? '!text-white dark:!text-black' : ''"
    >
      {{ preset.name }}
    </h3>

    <div>
      <div class="relative h-0 pb-[calc(calc(16/9)*100%)] w-full">
        <LayoutPreview :preset="preset" class="absolute inset-0" />
      </div>

      <template v-if="preset.origin === 'saved-template'">
        <div class="relative h-0 p-0">
          <Popover>
            <PopoverTrigger @click.stop>
              <div class="rounded m-1 px-1 py-0 hover:bg-white text-black hover:text-gray-600 absolute right-0 hover:shadow-xl bottom-0 opacity-0 bg-white/50 group-hover:opacity-100 transition-all">
                <IconDots />
              </div>
            </PopoverTrigger>
            <PopoverContent @click.stop class="absolute flex flex-col p-0 w-auto left-0 bottom-0">
              <Button
                :disabled="isRemoving"
                variant="ghostDestructive"
                class="rounded-none flex items-center font-light cursor-pointer"
                @click="revealConfirmDialog(preset)"
              >
                <IconSaxTrash class="w-4 h-4" />
                <span class="mt-0.5">{{ isRemoving ? 'Removing..' : 'Remove template' }}</span>
              </Button>
            </PopoverContent>
          </Popover>
        </div>
      </template>
    </div>
  </article>
</template>

<style scoped lang="scss">

</style>