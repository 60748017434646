<script setup lang="ts">
import { ScrollArea } from '@/components/ui/scroll-area'
import LayoutCard from '@/areas/editor/pages/layouts/LayoutCard.vue'
import { computed, capitalize, ref } from 'vue'
import RadioToggleButton from '@/components-v2/data-input/RadioToggleButton.vue'
import { usePresets } from '@/areas/editor/@data/layouts'
import { useCurrentSegment } from '@/areas/editor/store/useCurrentSegment'
import { Button } from '@/components/ui/button'
import IconSaxExportSquare from '@/components/Icons/iconsax/IconSaxExportSquare.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { useRouter } from 'vue-router'

const segment = useCurrentSegment()

const { layouts } = usePresets()

const origins = computed(() => {

  const origins = [
    {
      value: null,
      label: 'All',
    },
    {
      value: 'custom-layout',
      label: 'Custom layouts',
    },
    {
      value: 'saved-template',
      label: 'Saved templates',
    },
    {
      value: 'prefab',
      label: 'Default layouts',
    }
  ]

  return origins.filter(({ value }) => !value || layouts.value.some(l => l.origin === value))
})

const selectedOrigin = ref(null)
const filteredLayouts = computed(() => {
  if (!selectedOrigin.value) {
    return layouts.value
  } else {
    return layouts.value.filter(l => l.origin === selectedOrigin.value)
  }
})

const router = useRouter()
</script>

<template>
  <div class="flex flex-col gap-1 md:gap-4 p-4 pb-0 2xl:p-8 h-full sm:h-screen">
    <header>
      <h2 class="text-xl font-semibold leading-snug">Choose your layout</h2>
      <p class="font-light hidden md:block">Customize your layout to your liking making a unique viewing experience</p>
    </header>

    <div class="flex flex-row flex-wrap gap-2" v-if="origins.some(o => o.value === 'custom-layout' || o.value === 'saved-template')">
      <RadioToggleButton v-for="tag in origins" :key="tag.label" :value="tag.value" v-model="selectedOrigin" size="sm" class="flex-col px-2 relative font-light data-[state=active]:font-semibold">
        <span class="absolute inset-auto">{{ capitalize(tag.label) }}</span>
        <span class="font-semibold invisible">{{ capitalize(tag.label) }}</span>
      </RadioToggleButton>
    </div>

    <Button
      v-if="origins.some(o => o.value === 'saved-template') && selectedOrigin === null || selectedOrigin === 'saved-template'"
      as='a' :href="router.resolve({ name: dashboardRouteNames.account.templates }).href" target="_blank"
      variant="link" class="cursor-pointer mr-auto font-light p-0" size="sm"
    >
      <IconSaxExportSquare class="h-3.5 w-3.5" />
      <span class="mt-0.5">Manage saved templates</span>
    </Button>

    <div class="min-h-0 overflow-y-auto -mx-4 px-4 2xl:-mx-8 2xl:px-8" v-if="segment">
      <div class="grid grid-cols-[repeat(auto-fill,_minmax(130px,_1fr))] gap-2">
        <LayoutCard v-for="preset of filteredLayouts" :key="preset.id" :preset="preset" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
