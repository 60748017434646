export const editorRouteNames = {
  root: 'editor-root',
  layouts: 'editor-layouts',
  text: 'editor-text',
  elements: 'editor-elements',
  stickers: 'editor-stickers',
  uploads: 'editor-uploads',
  gifs: 'editor-gifs',
  captions: 'editor-captions',
  export: 'editor-export',
  emotes: 'editor-emotes',
} as const
