export const getLastUsedSocialStickerKey = () => {
  return localStorage.getItem('lastSocialStickerKey');
}

export const setLastUsedSocialStickerKey = (socialStickerKey: string) => {
  localStorage.setItem('lastSocialStickerKey', socialStickerKey);
}

export const getLastUsedTextStickerKey = () => {
  return localStorage.getItem('lastTextStickerKey');
}

export const setLastUsedTextStickerKey = (textStickerKey: string) => {
  localStorage.setItem('lastTextStickerKey', textStickerKey);
}

export const getLastUsedGiphyStickerId = () => {
  return localStorage.getItem('lastGiphyStickerId');
}

export const setLastUsedGiphyStickerId = (giphyStickerId: string) => {
  localStorage.setItem('lastGiphyStickerId', giphyStickerId);
}

export const getLastUsedTwitchEmotesUserName = () => {
  return localStorage.getItem('lastTwitchEmotesUserName');
}

export const setLastUsedTwitchEmotesUserName = (twitchEmotesUserName: string) => {
  localStorage.setItem('lastTwitchEmotesUserName', twitchEmotesUserName);
}

export const getLastUsedCustomStickerFileUrl = () => {
  return localStorage.getItem('lastUsedCustomStickerFileUrl');
}

export const setLastUsedCustomStickerFileUrl = (lastUsedCustomStickerFileUrl: string) => {
  localStorage.setItem('lastUsedCustomStickerFileUrl', lastUsedCustomStickerFileUrl);
}

export const getLastUsedTwitchEmoteFileUrl = () => {
  return localStorage.getItem('lastUsedTwitchEmoteFileUrl');
}

export const setLastUsedTwitchEmoteFileUrl = (lastUsedTwitchEmoteFileUrl: string) => {
  localStorage.setItem('lastUsedTwitchEmoteFileUrl', lastUsedTwitchEmoteFileUrl);
}