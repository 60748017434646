import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useSLVideoController } from '@/modules/SLVideoplayer/hooks/useSLVideoController'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { gsap } from 'gsap'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { useRafFn } from '@vueuse/core'
import * as Sentry from '@sentry/vue'

export const _globalTimeline = gsap.timeline({
  repeat: -1,
  repeatDelay: 0,
  paused: true,
})

export const useVideoStore = defineStore('video', () => {

  // we won't expose this element directly
  const videoElement = ref<HTMLVideoElement>()
  const src = ref('')

  const segmentsStore = useSegmentsStore()
  const segments = segmentsStore.flat()

  const videoSize = ref<{ width: number, height: number } | null>(null)

  const videoController = useSLVideoController(videoElement, {
    segments,
    src,
    globalTimeline: _globalTimeline,
  })

  const canvas = ref<OffscreenCanvas>()
  useRafFn(() => {
    if (videoElement.value) {
      canvas.value
        ?.getContext('2d')
        ?.drawImage(videoElement.value, 0, 0, canvas.value.width, canvas.value.height)
    }
  })

  function resizeCanvas(width: number, height: number) {
    if (canvas.value) {
      canvas.value.width = Math.abs(Math.round(width))
      canvas.value.height = Math.abs(Math.round(height))
    }
  }

  const preservedPaused = ref(false)

  const preservedMuted = ref(true)
  watch(videoController.muted, (muted) => {
    preservedMuted.value = muted
  })

  const scrubbing = ref(false)

  const isLoaded = computed(() => {
    return videoElement.value !== undefined
  })

  function getExactTime() {
    return (videoElement.value?.currentTime ?? 0) * 1000
  }

  async function loadVideo(element: HTMLVideoElement, _src: string) {
    return new Promise<void>((resolve, reject) => {

      videoElement.value = element;

      const isVideoInvalid = () => {
        const hasInvalidDuration = isNaN(element.duration) || !isFinite(element.duration);
        const hasInvalidDimensions = element.videoWidth === 0 || element.videoHeight === 0 || isNaN(element.videoWidth) || isNaN(element.videoHeight);
        return { hasInvalidDuration, hasInvalidDimensions };
      }

      function handleVideoState() {

        const { hasInvalidDuration, hasInvalidDimensions } = isVideoInvalid();

        if (!hasInvalidDuration && !hasInvalidDimensions) {
          cleanup();
          resolve();
        } else if (element.readyState >= HTMLMediaElement.HAVE_CURRENT_DATA) {
          cleanup();
          const error = new Error(hasInvalidDuration ? 'Video has no duration' : 'Video has no dimensions');
          console.error(JSON.stringify(element));
          Sentry.captureException(error);
          reject(error);
        }
      }
      
      function handleError(event: Event) {
        console.error(JSON.stringify(event));
        Sentry.captureException(new Error('Video failed to load'));
        handleVideoState()
      }

      function cleanup() {
        element.removeEventListener('error', handleError);
        element.removeEventListener('loadedmetadata', handleVideoState);
        element.removeEventListener('loadeddata', handleVideoState);
        element.removeEventListener('canplay', handleVideoState);
        element.removeEventListener('canplaythrough', handleVideoState);
      }

      element.addEventListener('error', handleError);
      element.addEventListener('loadedmetadata', handleVideoState);
      element.addEventListener('loadeddata', handleVideoState);
      element.addEventListener('canplay', handleVideoState);
      element.addEventListener('canplaythrough', handleVideoState);

      videoController.muted.value = preservedMuted.value;
      src.value = _src;
    }).then(() => {
      videoSize.value = { width: element.videoWidth, height: element.videoHeight };
      canvas.value = new OffscreenCanvas(element.videoWidth, element.videoHeight);
      setMainStore(element);
    });
  }

  function setMainStore(element: HTMLVideoElement) {
    const editorMainStore = useEditorMainStore()
    if (editorMainStore.videoHeight === 0 || editorMainStore.trimmedEndTime === 0) {
      // editorMainStore.videoDuration = Math.round(element.duration * 1000)
      editorMainStore.videoHeight = element.videoHeight
      editorMainStore.videoWidth = element.videoWidth
    }
  }

  function unmountElement() {
    // When the video element is removed from the DOM, the src attribute should be set to an empty string to allow garbage collecting.
    // https://html.spec.whatwg.org/multipage/media.html#best-practices-for-authors-using-media-elements
    if (videoElement.value) {
      videoElement.value.pause()
      videoElement.value.src = ''
      videoElement.value.load()
      videoElement.value = undefined
      src.value = ''
    }
  }

  return {
    ...videoController,
    durationMs: computed(() => videoController._duration.value * 1000),
    canvas,
    resizeCanvas,
    segments,
    loadVideo,
    getExactTime,
    videoElement,
    videoSize,
    scrubbing,
    isLoaded,
    unmountElement,
    preservedPaused
    // timeline: timeline,
  }
})
