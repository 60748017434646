import { posthog } from 'posthog-js'
import { ref, type Ref, reactive, watch, computed } from 'vue'

export const FEATURE_FLAGS = {
  'three-column-layout': {
    variants: ['control', 'wide'],
  },
  'clip-gpt': {
    variants: ['control', 'clipgpt'],
  },
  'upgrade-dialog-two-cards-with-two-steps': {
    variants: ['control', 'two-steps'],
  },
  'elgato-contest-banner-variants-v2': {
    variants: ['control', 'green-green', 'yellow-green', 'yellow-blue', 'green-purple', 'yellow-pink'],
  },
  'show-apps-in-cards-in-dashboard': {
    variants: ['control', 'different-dashboard-layout'],
  },
  'rounded-tier-cards': {
    variants: ['control', 'rounded-cards'],
  },
} as const satisfies Record<string, { variants: Readonly<['control', ...string[]]> }>

const featureFlags = reactive<Record<string, boolean | string | undefined>>({})

const client = posthog
client.onFeatureFlags((featureFlagsKeys) => {
  featureFlagsKeys.forEach((key) => {
    featureFlags[key] = client.getFeatureFlag(key)
  })

  Object.keys(featureFlags).forEach((key) => {
    if (!featureFlagsKeys.includes(key)) {
      delete featureFlags[key]
    }

    if (key in FEATURE_FLAGS) {
      resetFeatureFlagIfUnknown(key as keyof typeof FEATURE_FLAGS)
    }
  })
})

function resetFeatureFlagIfUnknown<T extends keyof typeof FEATURE_FLAGS>(key: T) {
  const variants = FEATURE_FLAGS[key].variants as Readonly<string[]>

  if (!variants.includes(featureFlags[key] as string)) {
    featureFlags[key] = 'control'
  }
}

export function useFeatureFlagEnabled(key: string): Ref<boolean | string | undefined> {
  const flag = ref(featureFlags[key])

  watch(
    () => featureFlags[key],
    (value) => {
      if (value === undefined) return
      if (value !== flag.value) {
        flag.value = value
      }
    }
  )

  return flag
}

export function useFeatureFlagVariantEnabled<TKey extends keyof typeof FEATURE_FLAGS>(
  key: TKey,
  variant: (typeof FEATURE_FLAGS)[TKey]['variants'][number]
): Ref<boolean | string | undefined> {
  const flag = useFeatureFlagEnabled(key)
  return computed(() => flag.value === variant)
}

export function useFeatureFlag<TKey extends keyof typeof FEATURE_FLAGS>(key: TKey) {
  return computed(() => featureFlags[key] as (typeof FEATURE_FLAGS)[TKey]['variants'][number])
}

export function useElgatoContestBannerV2() {

  const variant = useFeatureFlag('elgato-contest-banner-variants-v2')
  const data = computed(() => {
    switch (variant.value ?? 'control') {
      case 'green-green':
        return {
          gradient: 'bg-gradient-to-r from-[#6EFF8E] to-[#00876E]',
          banner: '/images/contest/green-green/banner.jpg',
          showcase: '/images/contest/green-green/showcase.jpg',
        }
      case 'yellow-green':
        return {
          gradient: 'bg-gradient-to-r from-[#F8D650] to-[#7FDF8F] to-[174.52%]',
          banner: '/images/contest/yellow-green/banner.jpg',
          showcase: '/images/contest/yellow-green/showcase.jpg',
        }
      case 'yellow-blue':
        return {
          gradient: 'bg-gradient-to-r from-[#FBE961] -from-[15%] via-[#5AE68A] via-[10.95%] to-[#4C87F7] to-[82.82%]',
          banner: '/images/contest/yellow-blue/banner.jpg',
          showcase: '/images/contest/yellow-blue/showcase.jpg',
        }
      case 'green-purple':
        return {
          gradient: 'bg-gradient-to-r from-[#90F496] to-[26.12%] to-[#D786F8] to-[90.72%]',
          banner: '/images/contest/green-purple/banner.jpg',
          showcase: '/images/contest/green-purple/showcase.jpg',
        }
      case 'yellow-pink':
        return {
          gradient: 'bg-gradient-to-r from-[#F8D650] to-[38.23%] to-[#D786F8] to-[87.22%]',
          banner: '/images/contest/yellow-pink/banner.jpg',
          showcase: '/images/contest/yellow-pink/showcase.jpg',
        }
      case 'control':
      default:
        return {
          gradient: 'bg-gradient-to-r from-[#6EFF8E] to-[#00876E]',
          banner: '/images/contest/control/banner.png',
          showcase: '/images/contest/control/showcase.png',
        }
    }
  })

  const banner = computed(() => data.value.banner)
  const showcase = computed(() => data.value.showcase)
  const gradient = computed(() => data.value.gradient)
  
  return { banner, showcase, gradient, variant }
}

export function useShowDifferentDashboardLayout() {
  return useFeatureFlagVariantEnabled('show-apps-in-cards-in-dashboard', 'different-dashboard-layout');
}

export function useFeatureFlags() {
  return featureFlags
}
